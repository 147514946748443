import React from "react";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion';
import ContractorProjectPaymentsStore from './ContractorProjectPaymentsStore';
import ContractorProjectPaymentsService from './ContractorProjectPaymentsService';
import ContractorProjectEnum from "../../constants/ContractorProjectEnum";
import { ToastContainer, toast } from 'react-toastify';
import '../../reactToastify.css';
import translate from '../../Providers/i18n/Translate'
import { AppContext } from '../../Providers/context'
import { Redirect } from 'react-router-dom';

class BreakDownInfoComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            showBg: false,
            response: "",
            isSuccess: false,
            message: "",
            object: this.props.object,
            transactionType: ContractorProjectEnum.TransactionType,
            alert: false,
            isPaymentOwner: false,
            detailResponse: '',
            typingTimeout: 0,
            paymentPercentage: 0,
            contractPercentage: 0,
            actualDone: 0,
            popUpPaymentPercentage: 0,
            popUpworkId: '',
            popUpBoq: '',
            detailIsSuccess: false,
            isGetBoqList: false
        }
        this._onChange = this._onChange.bind(this);
    }
    
    isValidated() {
        let isDataValid = false;
        var { response, object } = this.state;
        if (this.getCheckBoxState() && response.paymentOwner <= 0) {
            this.setState({ alert: false, isPaymentOwner: true })
        } else {
            //this check is for grants payments
            if (object.source === "TQL") {
                if (response.details[0].paymentPercentage > response.details[0].actualDone || response.percentageCompleted >= 100) {
                    isDataValid = true;
                } else {
                    isDataValid = false;
                    this.setState({ alert: true, isPaymentOwner: false })
                }
            } else {
                if (response.validPaymentCheck || response.percentageCompleted >= 100) {//totalBillPayment >= ((object.loanAmount / 100) * 10)
                    isDataValid = true;
                } else {
                    isDataValid = false;
                    this.setState({ alert: true, isPaymentOwner: false })
                }
            }
        }
        return isDataValid;
    }

    componentDidMount() {
        if (!this.state.response && localStorage.getItem('accessToken') && localStorage.getItem('accessToken').length > 15) {
            this.getBreakDown();
        }
        ContractorProjectPaymentsStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        ContractorProjectPaymentsStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.setStatePPBreakDown());
        this.setState(this.getOwnerHeaderDetailsResponse());
        this.submitLableHandler();
        this.submitHandler();
        if (this.state.isGetBoqList && this.state.detailIsSuccess) {
            this.getBreakDown();
            this.setState({
                isGetBoqList: false
            })
        }
    }

    setStatePPBreakDown() {
        return {
            response: ContractorProjectPaymentsStore.responsePaymentHeader,
            isSuccess: ContractorProjectPaymentsStore.isSuccessPaymentHeader(),
            message: ContractorProjectPaymentsStore.messagePaymentHeader,
        };
    }

    getBreakDown() {
        ContractorProjectPaymentsService.projectPaymentsBreakDownInfo(this.state.object.id, this.state.transactionType, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        console.log("Error logging in : ", errorDescription);
    }

    submitHandler = () => {
        this.props.handlerFromParant(this.state.message, this.state.response);
    }

    setSelectedInput = () => {
        this.props.selectInput();
    }

    getCheckBoxState = () => {
        var isCheckBoxChecked = this.props.checkCheckBoxState(this.state.response);
        return isCheckBoxChecked;
    }

    alertClose = () => {
        this.setState({
            alert: !this.state.alert
        })
    }

    alertCloseSecond = () => {
        this.setState({
            isPaymentOwner: !this.state.isPaymentOwner
        })
    }

    redirect() {
        return (
            <div>
                {this.state.isRedirect ? <Redirect
                    to={{
                        pathname: '/viewBOQSummary',
                        state: { item: this.state.object }
                    }}
                /> : null}
            </div>
        )
    }

    handleOnChange = e => {
        const { target: { value, name } } = e;
        this.setState({
            [name]: Boolean(value)
        });
    }

    handlePDF = () => {
        localStorage.setItem('ownerScope', false)
        this.setState({
            isRedirect: true
        })
    }

    modifyClick(boq) {
        this.setState({
            popUpBoq: boq,
            popUpPaymentPercentage: boq.paymentPercentage,
            popUpworkId: boq.workId

        })
        this.setState({
            showBg: !this.state.showBg,
        })
    }

    handleChange = (event) => {
        this.setState({
            popUpPaymentPercentage: event.target.value
        });
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.onClick();
        }
    }

    onClick = () => {
        this.sendApiCall(this.state.popUpBoq, this.state.popUpPaymentPercentage, this.state.popUpBoq.contractPercentage, this.state.popUpBoq.actualDone);
        this.setState({
            showBg: !this.state.showBg,
            isSendApiCall: true,
            isGetBoqList: true
        })
    }

    sendApiCall = (boq, paymentPercentage, contractPercentage, actualDone) => {
        if (paymentPercentage > contractPercentage) {
            toast.error(translate('lessThanEqualContractPer'));
            return;
        }
        if (paymentPercentage < actualDone) {
            toast.error(translate('lessThanEqualActualDone'));
            return;
        }
        ContractorProjectPaymentsService.contractorAddPaymentHeaderDetail(boq.id, this.state.response.id, this.state.response.contractId,
            boq.workId, paymentPercentage, boq.note, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    getOwnerHeaderDetailsResponse() {
        return {
            detailResponse: ContractorProjectPaymentsStore.detailResponse,
            detailIsSuccess: ContractorProjectPaymentsStore.isDetailSuccess(),
            detailMessage: ContractorProjectPaymentsStore._detailMessage,
        };
    }

    submitLableHandler = () => {
        if (this.state.detailResponse) {
            this.props.handlerLableFromParant(this.state.detailResponse);
            if (this.state.isSendApiCall) {
                toast.success(translate('savedSucc'), { autoClose: 2000 });
                this.setState({
                    isSendApiCall: false
                })
            }
        }
    }

    render() {
        const { response, object } = this.state;
        if (response) {
            response.details.sort((a, b) => a.workId - b.workId);
        }
        var paymentPerc = ''
        if (this.state.popUpPaymentPercentage === '') {
            paymentPerc = ''
        } else {
            paymentPerc = this.state.popUpPaymentPercentage
        }
        return (
            <div className="col-md-12">
                <ToastContainer autoClose={5000} position={toast.POSITION.TOP_CENTER} />
                {/* Upload File */}
                {this.state.showBg ? <div className="PopupBk"></div> : null}
                {this.state.showBg ? <div className="uploadPopup modifyNewProjectPopup">
                    <div className="row no-gutters formSection">
                        <div className="col-3"><label>{translate('payment')} : </label></div>
                        <div className="col-9"><input onKeyDown={this.handleKeyPress} value={paymentPerc} type="number" onChange={this.handleChange.bind(this)} /></div>
                        <div className="col-3"></div>
                        <div className="col-9 modifyFormSectionBtn">
                            <button onClick={this.onClick} >{translate('saveAndClose')}</button>
                            <button onClick={this.modifyClick.bind(this, '')}>{translate('cancle')}</button>
                        </div>
                    </div>
                </div> : null}
                {/* End Upload File */}
                <div className="row">
                    {/* <div className="col-md-12 project_step_top_Btns">
                         <div>
                             <button className="printBtn" onClick={this.handlePDF}>{translate('viewBOQAndPrint')}</button>
                             {this.redirect()}
                         </div>
                     </div> */}
                    <div className="contractorPaymentTable project_step_table_header">
                        <table>
                            <tbody>
                                <tr>
                                    <th>{translate('srNo')}</th>
                                    <th>{translate('descriptionOfwork')}</th>
                                    <th>{translate('contractPercent')} </th>
                                    <th>{translate('actual%Done')}</th>
                                    <th>{translate('payment')}</th>
                                    <th>{translate('viewComment')}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="project_step_wrapper contractorPaymentTable">
                        <div className="project_step_table_contantSection">
                            {object.source == "ENG" ? <Accordion allowZeroExpanded={true} allowMultipleExpanded={true} preExpanded={['1', '2', '3', '4', '5', '6', '7', '8',
                                '9', '10', '11', '12', '13', '14', '15', '16', '17']}>
                                {/* set1 */}
                                <AccordionItem className="widgetWrapper" uuid="1">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('mobilisation')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.filter(object => object.workId == 11).map(value => (
                                            <div key={value.workId} className="project_step_table_contant">
                                                <table >
                                                    <tbody >
                                                        {this.tableColumns(translate('mobSiteOfficeServices'), value)}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set2 */}
                                <AccordionItem className="widgetWrapper" uuid="2">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('excavationBFilling')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody >
                                                                {value.workId == 21 ?
                                                                    this.tableColumns(translate('excavation'), value)
                                                                    : null}
                                                                {value.workId == 22 ?
                                                                    this.tableColumns(translate('BackFilling'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set3 */}
                                <AccordionItem className="widgetWrapper" uuid="3">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('subStructure')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 31 ?
                                                                    this.tableColumns(translate('villa'), value)
                                                                    : null}
                                                                {value.workId == 32 ?
                                                                    this.tableColumns(translate('villaCompundWall'), value)
                                                                    : null}
                                                                {value.workId == 33 ?
                                                                    this.tableColumns(translate('VillaServantBlock'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set4 */}
                                <AccordionItem className="widgetWrapper" uuid="4">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('superStructure')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 41 ?
                                                                    this.tableColumns(translate('villaGfSlab'), value)
                                                                    : null}
                                                                {value.workId == 42 ?
                                                                    this.tableColumns(translate('villaFfSlab'), value)
                                                                    : null}
                                                                {value.workId == 43 ?
                                                                    this.tableColumns(translate('compoundWall'), value)
                                                                    : null}
                                                                {value.workId == 44 ?
                                                                    this.tableColumns(translate('servantBlock'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set5 */}
                                <AccordionItem className="widgetWrapper" uuid="5">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('blockWork')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 51 ?
                                                                    this.tableColumns(translate('solidBlockVillaCw'), value)
                                                                    : null}
                                                                {value.workId == 52 ?
                                                                    this.tableColumns(translate('villaGfWalls'), value)
                                                                    : null}
                                                                {value.workId == 53 ?
                                                                    this.tableColumns(translate('villaFfWalls'), value)
                                                                    : null}
                                                                {value.workId == 54 ?
                                                                    this.tableColumns(translate('compoundWall'), value)
                                                                    : null}
                                                                {value.workId == 55 ?
                                                                    this.tableColumns(translate('servantBlock1'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set6 */}
                                <AccordionItem className="widgetWrapper" uuid="6">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('plasterWork')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 61 ?
                                                                    this.tableColumns(translate('villaInteralPlaster'), value)
                                                                    : null}
                                                                {value.workId == 62 ?
                                                                    this.tableColumns(translate('villaExternalPlaster'), value)
                                                                    : null}
                                                                {value.workId == 63 ?
                                                                    this.tableColumns(translate('compoundWall'), value)
                                                                    : null}
                                                                {value.workId == 64 ?
                                                                    this.tableColumns(translate('servantBlock'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set7 */}
                                <AccordionItem className="widgetWrapper" uuid="7">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('electricWork')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 71 ?
                                                                    this.tableColumns(translate('conduits'), value)
                                                                    : null}
                                                                {value.workId == 72 ?
                                                                    this.tableColumns(translate('wiring'), value)
                                                                    : null}
                                                                {value.workId == 73 ?
                                                                    this.tableColumns(translate('accessories'), value)
                                                                    : null}
                                                                {value.workId == 74 ?
                                                                    this.tableColumns(translate('lightFittingsFans'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set8 */}
                                <AccordionItem className="widgetWrapper" uuid="8">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('plumbingWork')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 81 ?
                                                                    this.tableColumns(translate('waterSupplyPipes'), value)
                                                                    : null}
                                                                {value.workId == 82 ?
                                                                    this.tableColumns(translate('drainagePipes'), value)
                                                                    : null}
                                                                {value.workId == 83 ?
                                                                    this.tableColumns(translate('sanitaryWaresWaterHeaters'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set9 */}
                                <AccordionItem className="widgetWrapper" uuid="9">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('plumbingWorkExt')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 91 ?
                                                                    this.tableColumns(translate('drainagePipes'), value)
                                                                    : null}
                                                                {value.workId == 92 ?
                                                                    this.tableColumns(translate('waterTanksPumps'), value)
                                                                    : null}
                                                                {value.workId == 93 ?
                                                                    this.tableColumns(translate('septicTankAndSoakaway'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set10 */}
                                <AccordionItem className="widgetWrapper" uuid="10">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('internalFinishes')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 101 ?
                                                                    this.tableColumns(translate('floorTiles'), value)
                                                                    : null}
                                                                {value.workId == 102 ?
                                                                    this.tableColumns(translate('wallTiles'), value)
                                                                    : null}
                                                                {value.workId == 103 ?
                                                                    this.tableColumns(translate('paints'), value)
                                                                    : null}
                                                                {value.workId == 104 ?
                                                                    this.tableColumns(translate('marble'), value)
                                                                    : null}
                                                                {value.workId == 105 ?
                                                                    this.tableColumns(translate('falseCeiling'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set11 */}
                                <AccordionItem className="widgetWrapper" uuid="11">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('externalFinishes')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 111 ?
                                                                    this.tableColumns(translate('paints'), value, this.state.show111CmdBtn, "show111CmdBtn")
                                                                    : null}
                                                                {value.workId == 112 ?
                                                                    this.tableColumns(translate('compoundWallPaints'), value)
                                                                    : null}
                                                                {value.workId == 113 ?
                                                                    this.tableColumns(translate('interlock'), value)
                                                                    : null}
                                                                {value.workId == 114 ?
                                                                    this.tableColumns(translate('marble'), value)
                                                                    : null}
                                                                {value.workId == 115 ?
                                                                    this.tableColumns(translate('RoofClayTiles'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set12 */}
                                <AccordionItem className="widgetWrapper" uuid="12">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('waterProofing')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 121 ?
                                                                    this.tableColumns(translate('footingsVilla'), value)
                                                                    : null}
                                                                {value.workId == 122 ?
                                                                    this.tableColumns(translate('roof'), value)
                                                                    : null}
                                                                {value.workId == 123 ?
                                                                    this.tableColumns(translate('bathrooms'), value)
                                                                    : null}
                                                                {/* {value.workId == 124 ?
                                                                    this.tableColumns(translate('marble'), value, this.state.show124CmdBtn, "show124CmdBtn")
                                                                    : null} */}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set13 */}
                                <AccordionItem className="widgetWrapper" uuid="13">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('aluminiumWorks')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 131 ?
                                                                    this.tableColumns(translate('doors'), value)
                                                                    : null}
                                                                {value.workId == 132 ?
                                                                    this.tableColumns(translate('windows'), value)
                                                                    : null}
                                                                {value.workId == 133 ?
                                                                    this.tableColumns(translate('handrail'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set14 */}
                                <AccordionItem className="widgetWrapper" uuid="14">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('gatesMetalWorks')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 141 ?
                                                                    this.tableColumns(translate('gates'), value)
                                                                    : null}
                                                                {value.workId == 142 ?
                                                                    this.tableColumns(translate('spiralStair'), value)
                                                                    : null}
                                                                {value.workId == 143 ?
                                                                    this.tableColumns(translate('CarShed'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set15 */}
                                <AccordionItem className="widgetWrapper" uuid="15">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('joineryWork')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 151 ?
                                                                    this.tableColumns(translate('doors'), value)
                                                                    : null}
                                                                {value.workId == 152 ?
                                                                    this.tableColumns(translate('kitchenCabinaets'), value)
                                                                    : null}
                                                                {value.workId == 153 ?
                                                                    this.tableColumns(translate('Pergola'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set16 */}
                                <AccordionItem className="widgetWrapper" uuid="16">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('airConditioningWork')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 161 ?
                                                                    this.tableColumns(translate('machinesUnit'), value)
                                                                    : null}
                                                                {value.workId == 162 ?
                                                                    this.tableColumns(translate('ductsDiffusers'), value)
                                                                    : null}
                                                                {value.workId == 163 ?
                                                                    this.tableColumns('CivilWork', value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set17 */}
                                <AccordionItem className="widgetWrapper" uuid="17">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('cleaning')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map((value, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="project_step_table_contant">
                                                        <table>
                                                            <tbody>
                                                                {value.workId == 171 ?
                                                                    this.tableColumns(translate('cleaningLevelingHandingOver'), value)
                                                                    : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            );
                                        }) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion> : null}

                            {/* grant */}
                            {object.source == "TQL" ? <Accordion allowZeroExpanded={true} allowMultipleExpanded={true} preExpanded={['1']}>
                                {/* set1 */}
                                <AccordionItem className="widgetWrapper" uuid="1">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('grantBoq')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        {response ? response.details.map(value => (
                                            <div key={value.workId} className="project_step_table_contant">
                                                <table >
                                                    <tbody >
                                                        {this.tableColumns(translate('grantBoq'), value)}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )) : null}
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion> : null}

                            {this.state.object && this.state.object.source === "TQL" && this.state.alert ?
                                <div className="alert alert alert-danger" role="alert">
                                    <button onClick={this.alertClose.bind(this)} type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    {translate('grantPaymentMsg')}
                                </div> : null}

                            {this.state.object && this.state.object.source !== "TQL" && this.state.alert ?
                                <div className="alert alert alert-danger" role="alert">
                                    <button onClick={this.alertClose.bind(this)} type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    {translate('totalPercentageMsg')}
                                </div> : null}

                            {this.state.isPaymentOwner ?
                                <div className="alert alert alert-danger" role="alert">
                                    <button onClick={this.alertCloseSecond.bind(this)} type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    {translate('kindlySpecify')}
                                </div> : null}
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    onChangeValue = (boq, event) => {
        var payPercentage = Number(event.target.value);
        var contPercentage = Number(boq.contractPercentage);
        var actualDone = Number(boq.actualDone);
        this.setState({
            paymentPercentage: payPercentage,
            contractPercentage: contPercentage,
            actualDone: actualDone,
        });
    }
    
    _onBlur = (boq, event) => {
        this.sendApiCall(boq, this.state.paymentPercentage, this.state.contractPercentage, this.state.actualDone);
    }

    tableColumns(name, boq) {
        return (
            <tr >
                <td >{boq.workId}</td>
                <td>{name}</td>
                <td >
                    <label className="hide"></label>
                    <input type="text" defaultValue={boq.contractPercentage} maxLength="8" disabled />
                </td>
                <td >
                    <input type="text" defaultValue={boq.actualDone} maxLength="8" disabled />
                </td>
                <td >
                    <input disabled type="number" value={boq.paymentPercentage} maxLength="6" />
                </td>
                <td className="stepCommentsWrapper">
                    {boq.note == null ? <input disabled type="text" value='' /> : <input disabled type="text" value={boq.note} />}
                    <button>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        {boq.note ? <span className="tooltiptext">{boq.note}</span> : null}
                    </button>
                </td>
                <td className="text-center"><button className="modifiBtn modify" onClick={this.modifyClick.bind(this, boq)}><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/modify_boq.svg" alt="edit" /></i>{translate('modify')}</button></td>
            </tr>
        );
    }
}

export default BreakDownInfoComponent;
