var BASE_URL = process.env.API_BASE_URL
export default {
    BASE_URL: BASE_URL,
    HOUSING_APPLICATION_STATUS_URL: BASE_URL + 'public/v1/application/getstatus?fileNumber=',
    HOUSING_APPLICATION_STATUS: "HOUSING_APPLICATION_STATUS",
    MAINTENANCE_STATUS_URL: BASE_URL + 'public/v1/application/getmaintenancerequests?fileNumber=',
    MAINTENANCE_STATUS: "MAINTENANCE_STATUS",
    PAYMENT_STATUS_URL: BASE_URL + 'contractor/v1/chatbot/projectsPaymentByApplicationNo?applicationNo=',
    publicGetRequiredDocumentsURL: BASE_URL + '/documentrequired/v1/customerDocument/required/list',
    publicViewDocumentsURL: BASE_URL + '/documentrequired/v1/customerDocument/view',
    publicUploadDocumentsURL: BASE_URL + '/documentrequired/v1/customerDocument/attach',
    publicDeleteDocumentsURL: BASE_URL + '/documentrequired/v1/customerDocument/delete',
    publicFinishDocumentsSubmitURL: BASE_URL + '/documentrequired/v1/customerDocument/submit',
    PAYMENT_STATUS: "PAYMENT_STATUS"
}