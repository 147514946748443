import React from 'react'
import NoData from '../../utils/nodata'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'
import FinancialStatusWidgetItem from './financialstatus-widget-item'

class FinancialStatusWidget extends React.Component {
    constructor(props) {
        super()
        this.state = {
            financialStatusItem: null
        }
        this.fetchFinancialStatusData = this.fetchFinancialStatusData.bind(this)
        this.setFinancialStatus = this.setFinancialStatus.bind(this)
    }

    componentDidMount() {
        this.fetchFinancialStatusData(this.props.url)
    }

    fetchFinancialStatusData(url) {
        if (CONSTANTS.authToken.length > 15){
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setFinancialStatus).catch(this.loadFailed);
        }
    }

    loadFailed(response) {
        console.log(response)
    }

    setFinancialStatus(response) {
        if (response.success && response.payload.length > 0) {
            this.setState({ financialStatusItem: <FinancialStatusWidgetItem key={0} payload={response.payload[0]} index={0} /> })
        }
    }

    render() {
        return (
            <div className="col-md-12 financialStatusWidgetWrapper">
                <div className="widgetWrapper">
                    <div className="widgetHead">
                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/FinancialStatus.svg'} alt="Financial Status" /></span>
                        <span>{translate('financial-status-widget-heading')}</span>
                    </div>
                    <div className="widgetContant">
                        <div className="widgetContentIn">
                            {this.state.financialStatusItem != null ? this.state.financialStatusItem :
                                <NoData message={translate('nodata')} />}
                        </div>
                        {this.state.financialStatusItem != null ? <div className="widgetsMoreBtn">
                            <hr></hr>
                            <a href="/overduePayment">{translate('overduepayment')}<img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/arrow.svg'} alt="Click" /></a>
                        </div> : null}
                    </div>
                </div>
            </div>
        )
    }
}
export default FinancialStatusWidget