import React from "react"
import InputRange from 'react-input-range'
import Pagination from "react-js-pagination"
import GoogleMapReact from 'google-map-react'
import { ScrollToTop } from '../../utils/Scroll'
import { AppContext } from '../../Providers/context'
import HouseBookingStore from './housebooking-store'
import translate from '../../Providers/i18n/Translate'
import HouseBookingMapPin from './housebooking-map-pin'
import HouseBookingService from './housebooking-service'
import { ToastContainer, toast } from 'react-toastify'
import CONSTANTS from '../constants/dashboard-constants'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class GroupDetails extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        var filters = localStorage.filters && JSON.parse(localStorage.getItem('filters'))
        this.state = {
            area: { min: filters ? filters.areaRange.min : 0, max: filters ? filters.areaRange.max : 1 },
            price: { min: filters ? filters.priceRange.min : 0, max: filters ? filters.priceRange.max : 1 },
            page: 1,
            pageSize: 6,
            floorCount: '',
            roomsCount: '',
            styleValue: '',
            MinPrice: filters ? filters.priceRange.min : 0,
            MaxPrice: filters ? filters.priceRange.max : 1,
            MinArea: filters ? filters.areaRange.min : 0,
            MaxArea: filters ? filters.areaRange.max : 1,
            OrderBy: 1,
            villasArray: [],
            pagesNumbersArray: [],
            filterBubblesArray: [],
            payload: '',
            response: '',
            pinsArray: '',
            mapCenterLat: '',
            mapCenterLng: '',
            propertyId: props.location.state ? props.location.state.propertyId : '',
            filtersPayload: localStorage.filters && JSON.parse(localStorage.getItem('filters'))
        }
        this.onChange = this.onChange.bind(this)
        this.getVillasGroups = this.getVillasGroups.bind(this)
        this.removeBubbleClick = this.removeBubbleClick.bind(this)
        this.areaRageListener = this.areaRageListener.bind(this)
        this.priceRangeChangeListener = this.priceRangeChangeListener.bind(this)
        this.orderByHandler = this.orderByHandler.bind(this)
        this.getPins = this.getPins.bind(this)
        this.goToVillaDetail = this.goToVillaDetail.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
    }

    journeyClick() {
        this.setState({
            journeyBg: !this.state.journeyBg
        })
    }

    componentDidMount() {
        // ScrollToTop()
        // this.villasRequest()
        // HouseBookingStore.addChangeListener(this.onChange)
    }

    componentWillUnmount() {
        HouseBookingStore.removeChangeListener(this.onChange)
    }

    onChange() {
        if (HouseBookingStore.houseBookingVillas && HouseBookingStore.houseBookingVillas.payload) {
            var payload = HouseBookingStore.houseBookingVillas.payload
            var villasarray = this.getVillasGroups(payload)
            this.setState({ villasArray: villasarray }, () => {
                this.setState({ response: HouseBookingStore.houseBookingVillas, payload: payload, pinsArray: this.getPins(payload), mapCenterLat: payload.relatedProperties.length > 0 ? payload.relatedProperties[0].latitude : '', mapCenterLng: payload.relatedProperties.length > 0 ? payload.relatedProperties[0].longitude : '' })
            })
        } else {
            toast.error(HouseBookingStore.houseBookingVillas.message)
        }
    }

    getVillasGroups(villasGroups) {
        var array = []
        villasGroups.relatedProperties.map((villa) => {
            return (array.push(<Villa key={villa.id} payload={villa} goToVillaDetail={this.goToVillaDetail} />))
        })
        return array
    }

    villasRequest() {
        var url = CONSTANTS.houseBookingRelatedPropertyDetailsEndPoint + "?local=" + this.context.state.siteLang
        HouseBookingService.houseBookingVillasRequest(url, this.state.page - 1, this.state.pageSize, this.state.propertyId, this.state.price.min, this.state.price.max, this.state.area.min, this.state.area.max, this.state.OrderBy)
    }

    prepareBubble(name, labelVal, label, value) {
        var array = [...this.state.filterBubblesArray]
        var bubbleObject = { key: '', value: '' }
        bubbleObject.key = name
        bubbleObject.value = <Bubble key={name} name={name} label={label} value={labelVal} onRemoveClick={this.removeBubbleClick} />
        const index = this.state.filterBubblesArray.findIndex(bubble => bubble.key === name)
        if (index >= 0) {
            array[index] = bubbleObject
        } else {
            array.push(bubbleObject)
        }
        this.setFilterState(name, value, array)
    }

    setFilterState(name, value, array) {
        name === "area" && this.setState({ filterBubblesArray: array, area: value }, () => { this.villasRequest() })
        name === "price" && this.setState({ filterBubblesArray: array, price: value }, () => { this.villasRequest() })
    }

    handlePageChange(pageNumber) {
        if (pageNumber !== this.state.page) {
            this.setState({ page: pageNumber }, () => { this.villasRequest() })
        }
    }

    removeBubbleClick(e) {
        const { name } = e.target
        this.removeBubble(name)
    }

    removeBubble(name) {
        const index = this.state.filterBubblesArray.findIndex(bubble => bubble.key === name)
        if (index >= 0) {
            var array = [...this.state.filterBubblesArray]
            array.splice(index, 1);
            name === "area" && this.setFilterState(name, { min: this.state.filtersPayload.areaRange.min, max: this.state.filtersPayload.areaRange.max }, array)
            name === "price" && this.setFilterState(name, { min: this.state.filtersPayload.priceRange.min, max: this.state.filtersPayload.priceRange.max }, array)
        }
    }

    areaRageListener(area) {
        const { min, max } = area
        if (min > 0 || max < this.state.filtersPayload.areaRange.max) {
            this.prepareBubble("area", min + " - " + max, "BuildingArea", area)
        } else {
            this.removeBubble("area")
        }
    }

    priceRangeChangeListener(price) {
        const { min, max } = price
        if (min > 0 || max < this.state.filtersPayload.priceRange.max) {
            if (this.context.state.siteLang === 'en') {
                this.prepareBubble("price", min + " AED - " + max + " AED", "Price", price)
            } else {
                this.prepareBubble("price", min + " درهم - " + max + " درهم ", "Price", price)
            }

        } else {
            this.removeBubble("price")
        }
    }

    orderByHandler(e) {
        const { name, value } = e.target
        this.setState({ [name]: value }, () => { this.villasRequest() })
    }

    getPins(payload) {
        var pins = []
        if (payload.relatedProperties.length > 0) {
            payload.relatedProperties.map((villa, index) => {
                return (pins.push(<HouseBookingMapPin key={index} payload={villa} lat={villa.latitude} lng={villa.longitude} goToDetails={this.goToVillaDetail} showPopUp={false} />))
            })
        }
        return pins
    }

    goToVillaDetail(e) {
        e.preventDefault()
        this.props.history.push({
            pathname: '/houseDetails',
            state: { propertyId: parseInt(e.target.name, 10) }
        })
    }

    myBooking() {
        this.props.history.push({ pathname: '/myBooking' })
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('RelatedHouses')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-sm-12 service_contant_wrapper bookingCenterForm">
                        <label><strong>{translate("disabledServiceMessage")}</strong></label>
                    </div>
                </div>
            </div>
            // <div className="container-fluid">
            //     <ToastContainer autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
            //     <div className="container mainSection">
            //         <div className="row">
            //             {/* breadcrumb */}
            //             <div className="col-md-12">
            //                 <nav aria-label="breadcrumb">
            //                     <ol className="breadcrumb">
            //                         <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
            //                         <li className="breadcrumb-item"><a href="/houseBooking">{translate('HouseBooking')}</a></li>
            //                         <li className="breadcrumb-item active" aria-current="page">{translate('RelatedHouses')}</li>
            //                     </ol>
            //                 </nav>
            //             </div>
            //         </div>

            //         <div className="row">
            //             <div className="col-md-12">
            //                 <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} /></i><span>{translate('PurchaseReadyhouse')}</span>
            //                     {this.state.payload && this.state.payload.hasHouseBooked ? <button onClick={this.myBooking}>
            //                         <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="HousingRequest" />
            //                         <span>{translate('mybookings')}</span>
            //                     </button> : null}
            //                 </div>
            //             </div>
            //         </div>

            //         <div className="row">
            //             <div className="col-md-12">
            //                 <div className="bookingLocationWrapper">
            //                     <input defaultChecked="checked" id="tab1" type="radio" name="pct" />
            //                     <input id="tab2" type="radio" name="pct" />
            //                     <nav>
            //                         <ul>
            //                             <li className="tab1">
            //                                 <label htmlFor="tab1">
            //                                     <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/3dview.svg'} /></i>
            //                                     <span>{translate('3DView')}</span>
            //                                 </label>
            //                             </li>
            //                             <li className="tab2">
            //                                 <label htmlFor="tab2">
            //                                     <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/locationMap.svg'} /></i>
            //                                     <span>{translate('Locationonthemap')}</span>
            //                                 </label>
            //                             </li>
            //                         </ul>
            //                     </nav>
            //                     <section>
            //                         <div className="tab1">
            //                             <div className="villa3dimage"> <iframe src={this.state.payload.threeDimensionalHouseURL} /></div>
            //                         </div>
            //                         <div className="tab2">
            //                             <div className="villaLocationMap">
            //                                 <div id="map" style={{ height: '680px', width: '100%', position: 'relative' }}>
            //                                     {this.state.pinsArray? <GoogleMapReact
            //                                         bootstrapURLKeys={{ key: CONSTANTS.googleMapKey }}
            //                                         defaultCenter={{ lat: this.state.mapCenterLat ? parseFloat(this.state.mapCenterLat) : 0, lng: this.state.mapCenterLng ? parseFloat(this.state.mapCenterLng) : 0 }}
            //                                         defaultZoom={16}>
            //                                         {this.state.pinsArray}
            //                                     </GoogleMapReact> : null}
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </section>
            //                 </div>
            //             </div>
            //         </div>

            //         <div className="row">
            //             <div className="col-md-12">
            //                 {this.state.payload ? <OldBooking payload={this.state.payload} /> : null}
            //             </div>
            //         </div>

            //         <div className="row">
            //             <div className="col-md-12">
            //                 <div className="booking_filterWrapper">
            //                     <div className="row">
            //                         <div className="col-md-4 col-sm-6 houseDetailsFliterRange">
            //                             <span>{translate('Area')}</span>
            //                             <InputRange maxValue={this.state.MaxArea} formatLabel={value => value.toFixed(2)} minValue={this.state.MinArea} value={this.state.area} onChange={this.areaRageListener} />
            //                         </div>
            //                         <div className="col-md-4 col-sm-6 houseDetailsFliterRange">
            //                             <span>{translate('Price')}</span>
            //                             <InputRange maxValue={this.state.MaxPrice} formatLabel={value => this.context.state.siteLang === 'en' ? `${value} AED` : `${value} درهم`} minValue={this.state.MinPrice} value={this.state.price} onChange={this.priceRangeChangeListener} />
            //                         </div>
            //                         <div className="col-md-4 col-sm-12 houseDetailsFliterSelect">
            //                             <label htmlFor="orderBy">{translate('OrderBy')}</label>
            //                             {this.context.state.siteLang === 'en' ?
            //                                 <select id="orderBy" name="OrderBy" onChange={this.orderByHandler}>
            //                                     <option value="1">Lowest price</option>
            //                                     <option value="2">Highest price</option>
            //                                 </select>
            //                                 :
            //                                 <select id="orderBy" name="OrderBy" onChange={this.orderByHandler}>
            //                                     <option value="1">أقل سعر</option>
            //                                     <option value="2">أعلى سعر</option>
            //                                 </select>
            //                             }
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //         <div className="row">
            //             <div className="col-md-12">
            //                 {this.state.filterBubblesArray.map((bubble) => { return (bubble.value) })}
            //             </div>
            //         </div>

            //         <div className="row">
            //             {this.state.villasArray}
            //         </div>
            //         <div className="text-center">
            //             {this.state.payload ? <Pagination
            //                 pageRangeDisplayed={15}
            //                 activePage={this.state.page}
            //                 itemsCountPerPage={this.state.pageSize}
            //                 totalItemsCount={this.state.response.total}
            //                 onChange={this.handlePageChange}
            //             /> : null}
            //         </div>
            //     </div>
            // </div >
        );
    }
}

function Bubble(props) {
    return (
        <div className="chip">
            <button name={props.name} onClick={props.onRemoveClick}>X</button>
            <span>{translate(props.label)} : </span>
            <span>{props.value}</span>
        </div>
    )
}

function OldBooking(props) {
    return (
        <div className="service_contant_wrapper">
            <div className="col-md-6">
                <div className="row">
                    <div className="col-sm-6"><strong> {translate('projectName')} </strong> : </div>
                    <div className="col-sm-6"> {props.payload.projectName} </div>
                    <div className="col-sm-6"><strong> {translate('Style')} </strong> : </div>
                    <div className="col-sm-6"> {props.payload.villaStyle} </div>
                </div>
            </div>
            <div className="row no-gutters yourBookingDetails">
                <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Housing_Projects.svg'} /></i>
                    <span><strong> {translate('Zone')} : </strong> {props.payload.landLocation} </span>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} /></i>
                    <span><strong> {translate('NumberofFloors')} : </strong> {props.payload.floor} </span>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bed.svg'} /></i>
                    <span><strong> {translate('NumberofBedrooms')} : </strong> {props.payload.bedroom} </span>
                </div>
            </div>
        </div>
    )
}

function Villa(props) {
    return (
        <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="villaListpViewBox">
                <div className="villaListpViewBoxHead">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/area.svg'} /></i>
                    <span> {translate('Area')} : <strong> {props.payload.plotArea} </strong></span>
                </div>
                <div className="villaListpViewBoxPrice">
                    <span> {translate('Price')} : {props.payload.sellingPrice} </span>
                </div>
                <div className="villaListpViewBoxBtn">
                    <a href="#" name={props.payload.id} onClick={props.goToVillaDetail}>{translate('Viewdetails')}</a>
                </div>
            </div>
        </div>
    )
}
export default AuthenticatedComponent(GroupDetails)