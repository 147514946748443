import React from 'react'
import history from '../../History'
import DateFormater from 'dateformat'
import Pagination from "react-js-pagination"
import NumericInput from 'react-numeric-input'
import { ScrollToTop } from '../../utils/Scroll'
import HouseBookingStore from './housebooking-store'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../constants/dashboard-constants'
import HouseBookingService from './housebooking-service'
import { ToastContainer, toast } from 'react-toastify'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

const villaStyle = "Style"
const floorCount = "NumberofFloors"
const roomsCount = "NumberofBedrooms"
class HouseBookingServiceComponent extends React.Component {
    static contextType = AppContext
    constructor() {
        super()
        this.state = {
            journeyBg: false,
            oldBooking: false,
            oldBookingPayload: null,
            villasGroupsArray: [],
            styleValue: 0,
            floorCount: '',
            maxFloor: 0,
            roomsCount: '',
            maxBedRooms: 0,
            pagesNumbersArray: [],
            filterBubblesArray: [],
            pageSize: 6,
            villaStyleOptions: [],
            page: 1,
            filtersPayload: '',
            payload: '',
            response: ''
        }
        this.villaStyleListener = this.villaStyleListener.bind(this)
        this.removeBubbleClick = this.removeBubbleClick.bind(this)
        this.setFilterState = this.setFilterState.bind(this)
        this.prepareBubble = this.prepareBubble.bind(this)
        this.numberOfBedRoomsListener = this.numberOfBedRoomsListener.bind(this)
        this.numberOfFloorsListener = this.numberOfFloorsListener.bind(this)
        this.removeBubble = this.removeBubble.bind(this)
        this.onChange = this.onChange.bind(this)
        this.villasGroupsRequest = this.villasGroupsRequest.bind(this)
        this.goToPropertiesView = this.goToPropertiesView.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
    }

    componentDidMount() {
        // ScrollToTop()
        // HouseBookingService.houseBookingFiltersRequest(CONSTANTS.houseBookingFiltersEndPoint + "?local=" + this.context.state.siteLang)
        // HouseBookingService.houseBookingMyBookingsRequest(CONSTANTS.houseBookingMyBookingsEndPoint + "?local=" + this.context.state.siteLang)
        // this.villasGroupsRequest()
        // HouseBookingStore.addChangeListener(this.onChange)
    }

    villasGroupsRequest() {
        var url = CONSTANTS.houseBookingVillasGroupsEndPoint + "?local=" + this.context.state.siteLang
        HouseBookingService.houseBookingVillasGroupsRequest(url, this.state.page - 1, this.state.pageSize, this.state.floorCount, this.state.roomsCount, this.state.styleValue)
    }

    componentWillUnmount() {
        HouseBookingStore.removeChangeListener(this.onChange)
    }

    onChange() {
        if (HouseBookingStore.houseBookingMyBookings && HouseBookingStore.houseBookingMyBookings.payload) {
            this.setState({ oldBookingPayload: HouseBookingStore.houseBookingMyBookings.payload, oldBooking: true })
        }

        if (HouseBookingStore.houseBookingFilters && HouseBookingStore.houseBookingFilters.payload) {
            var payload = HouseBookingStore.houseBookingFilters.payload
            this.setState({ filtersPayload: payload, villaStyleOptions: this.getStyleOptions(payload.styles), maxFloor: payload.floors.length - 1, maxBedRooms: payload.bedrooms.length - 1 }, () => { localStorage.setItem("filters", JSON.stringify(payload)) })
        } else {
            HouseBookingStore.houseBookingFilters && toast.error(HouseBookingStore.houseBookingFilters.message)
        }

        if (HouseBookingStore.houseBookingVillasGroups && HouseBookingStore.houseBookingVillasGroups.payload) {
            this.setState({ response: HouseBookingStore.houseBookingVillasGroups, villasGroupsArray: this.getVillasGroups(HouseBookingStore.houseBookingVillasGroups.payload), payload: HouseBookingStore.houseBookingVillasGroups.payload })
        }
    }

    getVillasGroups(villasGroups) {
        var array = []
        villasGroups.map((villa) => {
            return (array.push(<Villa key={villa.id} payload={villa} goToPropertiesView={this.goToPropertiesView} />))
        })
        return array
    }

    getStyleOptions(stylesArray) {
        var styles = []
        { this.context.state.siteLang === 'en' ? styles.push(<option key={0} value={0}>Please select</option>) : styles.push(<option key={0} value={0}>الرجاء الاختيار</option>) }
        stylesArray.map((styleObject) => {
            return (styles.push(<option key={styleObject.id} value={styleObject.id}>{styleObject.style}</option>))
        })
        return styles
    }

    journeyClick() {
        this.setState({
            journeyBg: !this.state.journeyBg
        })
    }

    handlePageChange(pageNumber) {
        if (pageNumber !== this.state.page) {
            this.setState({ page: pageNumber }, () => { this.villasGroupsRequest() })
        }
    }

    removeBubbleClick(e) {
        const { name } = e.target
        this.removeBubble(name)
    }

    removeBubble(name) {
        const index = this.state.filterBubblesArray.findIndex(bubble => bubble.key === name)
        if (index >= 0) {
            var array = [...this.state.filterBubblesArray]
            array.splice(index, 1);
            this.setFilterState(name, '', array)
        }
    }

    villaStyleListener(e) {
        const { name, value } = e.target
        if (value !== "0") {
            var index = e.nativeEvent.target.selectedIndex;
            var label = e.nativeEvent.target[index].text;
            this.prepareBubble(name, value, label)
        } else {
            this.removeBubble(name)
        }
    }

    setFilterState(name, value, array) {
        name === villaStyle && this.setState({ filterBubblesArray: array, styleValue: value }, () => { this.villasGroupsRequest() })
        name === roomsCount && this.setState({ filterBubblesArray: array, roomsCount: value }, () => { this.villasGroupsRequest() })
        name === floorCount && this.setState({ filterBubblesArray: array, floorCount: value }, () => { this.villasGroupsRequest() })
    }

    numberOfFloorsListener(value) {
        if (value) {
            this.prepareBubble(floorCount, value)
        } else {
            this.removeBubble(floorCount)
        }
    }

    numberOfBedRoomsListener(value) {
        if (value) {
            this.prepareBubble(roomsCount, value)
        } else {
            this.removeBubble(roomsCount)
        }
    }

    prepareBubble(name, value, label) {
        var array = [...this.state.filterBubblesArray]
        var bubbleObject = { key: '', value: '' }
        bubbleObject.key = name
        bubbleObject.value = <Bubble key={name} label={name} value={label ? label : value} onRemoveClick={this.removeBubbleClick} />
        const index = this.state.filterBubblesArray.findIndex(bubble => bubble.key === name)
        if (index >= 0) {
            array[index] = bubbleObject
        } else {
            array.push(bubbleObject)
        }
        this.setFilterState(name, value, array)
    }

    goToPropertiesView(e) {
        e.preventDefault()
        this.props.history.push({
            pathname: '/groupDetails',
            state: { propertyId: parseInt(e.target.name, 10) }
        })
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('HouseBooking')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-sm-12 service_contant_wrapper bookingCenterForm">
                        <label><strong>{translate("disabledServiceMessage")}</strong></label>
                    </div>
                </div>
            </div>
        )
    }

    // render() {
    //     return (
    //         <div className="container-fluid">
    //             {history.replace({ pathname: '/customerDashboard' })}
    //             <ToastContainer autoClose={2000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
    //             {this.state.journeyBg ? <div className="userJurnyPopWrapper" onClick={this.journeyClick.bind(this)}></div> : null}
    //             {this.state.journeyBg ? <div className="userJurnyPopupBox">
    //                 <button className="popupClose" onClick={this.journeyClick.bind(this)}>X</button>
    //                 <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HouseBooking_UserJourney.jpg'} alt="HouseBooking" />
    //             </div> : null}

    //             <div className="container mainSection">
    //                 <div className="row">
    //                     {/* breadcrumb */}
    //                     <div className="col-md-12">
    //                         <nav aria-label="breadcrumb">
    //                             <ol className="breadcrumb">
    //                                 <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
    //                                 <li className="breadcrumb-item active" aria-current="page">{translate('HouseBooking')}</li>
    //                             </ol>
    //                         </nav>
    //                     </div>
    //                 </div>

    //                 <div className="row">
    //                     <div className="col-md-12">
    //                         <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ManageYourHouseBooking.svg'} alt="Manage Your House Booking" /></i><span>{translate('HouseBooking')}</span></div>
    //                     </div>
    //                 </div>

    //                 {this.state.oldBooking ? <OldBooking history={this.props.history}payload={this.state.oldBookingPayload} /> : null}

    //                 <div className="row">
    //                     <div className="col-md-12">
    //                         <div className="booking_filterWrapper">
    //                             <div className="bookingfilterCenter">
    //                                 <div className="row">
    //                                     <div className="col-sm-6 booking_filterCol">
    //                                         <label><strong>{translate('Style')}</strong></label>
    //                                         <select name={villaStyle} value={this.state.styleValue} onChange={this.villaStyleListener}>
    //                                             {this.state.villaStyleOptions}
    //                                         </select>
    //                                     </div>
    //                                     <div className="col-sm-3 booking_filterCol">
    //                                         <label><strong>{translate('NumberofFloors')}</strong></label>
    //                                         <NumericInput placeholder={this.context.state.siteLang === 'en' ? 'All' : 'الكل'} strict mobile name={floorCount} min={0} max={this.state.maxFloor} value={this.state.floorCount} onChange={this.numberOfFloorsListener} />
    //                                     </div>
    //                                     <div className="col-sm-3 booking_filterCol">
    //                                         <label><strong>{translate('NumberofBedrooms')}</strong></label>

    //                                         <NumericInput placeholder={this.context.state.siteLang === 'en' ? 'All' : 'الكل'} strict mobile name={roomsCount} min={0} max={this.state.maxBedRooms} value={this.state.roomsCount} onChange={this.numberOfBedRoomsListener} />
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>

    //                 <div className="row">
    //                     <div className="col-md-12">
    //                         <div className="warningMsgBox">
    //                             <span>
    //                                 <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/warrningmsg_icon.svg'} alt="warrningmsg" />
    //                             </span>
    //                             <span className="Message userJurny">
    //                                 {translate('Formoreinfo')} <button onClick={this.journeyClick.bind(this)} id="userJurnuBtn">{translate('clickhere')}</button>
    //                             </span>
    //                         </div>
    //                     </div>
    //                 </div>

    //                 <div className="row">
    //                     <div className="col-md-12">
    //                         {this.state.filterBubblesArray.map((bubble) => { return (bubble.value) })}
    //                     </div>
    //                 </div>

    //                 {this.state.villasGroupsArray ? <div className="row">
    //                     <div className="col-md-12">
    //                         <div className="houseBooking_wrapper">
    //                             <div className="row">
    //                                 {this.state.villasGroupsArray}
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div> : null}
    //                 <div className="text-center">
    //                     {this.state.payload ? <Pagination
    //                         pageRangeDisplayed={15}
    //                         activePage={this.state.page}
    //                         itemsCountPerPage={this.state.pageSize}
    //                         totalItemsCount={this.state.response.total}
    //                         onChange={this.handlePageChange}
    //                     /> : null}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }
}

function Bubble(props) {
    return (
        <div className="chip">
            <button name={props.label} onClick={props.onRemoveClick}>X</button>
            <span>{translate(props.label)} : </span>
            <span>{props.value}</span>
        </div>
    )
}

function OldBooking(props) {
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="service_contant_wrapper">
                    <div className="yourBookingHead">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row hBookrefNo">
                                    <div className="col-lg-6 col-sm-6"><strong>{translate('BookingReferenceNumber')}</strong> : </div>
                                    <div className="col-lg-6 col-sm-6"> {props.payload.referenceNo} </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="row  hBookresDt">
                                    <div className="col-lg-9 col-sm-6"><strong>{translate('BookedDate')}</strong> : </div>
                                    <div className="col-lg-3 col-sm-6"> {DateFormater(props.payload.bookingDate, 'dd/mm/yyyy')} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters yourBookingDetails">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-sm-6"><strong>{translate('projectName')}</strong> : </div>
                                <div className="col-sm-6"> {props.payload.projectName} </div>
                                <div className="col-sm-6"><strong>{translate('Style')}</strong> : </div>
                                <div className="col-sm-6"> {props.payload.villaStyle} </div>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters yourBookingDetails">
                        <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                            <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Housing_Projects.svg'} alt="Zone" /></i>
                            <span><strong>{translate('Zone')} : </strong> {props.payload.landLocation} </span>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                            <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Villa number" /></i>
                            <span><strong>{translate('VillaNo')} : </strong> {props.payload.villaNo} </span>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                            <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Floor" /></i>
                            <span><strong>{translate('NumberofFloors')} : </strong> {props.payload.floor} </span>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                            <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Land number" /></i>
                            <span><strong>{translate('LandNo')} : </strong> {props.payload.plotNumber} </span>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                            <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/area.svg'} alt="Building area" /></i>
                            <span><strong>{translate('BuildingArea')} : </strong> {props.payload.basedArea} </span>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                            <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bed.svg'} alt="Number of bedroom" /></i>
                            <span><strong>{translate('NumberofBedrooms')} : </strong> {props.payload.bedroom} </span>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                            <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/area.svg'} alt="Land area" /></i>
                            <span><strong>{translate('Landarea')} : </strong> {props.payload.plotArea} </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <button onClick={() => { props.history.push({ pathname: '/myBooking' }) }}>
                                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="HousingRequest" />
                                    <span>{translate('mybookings')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Villa(props) {
    return (
        <div className="col-md-4 col-sm-6">
            <div className="houseBookingBox">
                <div className="houseImage">
                    <div className="houseBookinType">
                        <span>{props.payload.villaStyle}</span>
                    </div>
                    <div className="numberUnit">
                        <span>{translate('NumberofUnitsAvailable')} {props.payload.totalUnits}</span>
                    </div>
                    <img src={props.payload.images[0] && props.payload.images[0].image1} />
                </div>
                <div className="houseDetailsWrapper">
                    <strong> {props.payload.projectName} </strong>
                    <div className="houseDetails">
                        <ul>
                            <li> <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} /></i>
                                <span>{translate('NumberofFloors')} : {props.payload.floor}</span>
                            </li>
                            <li><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bed.svg'} /></i>
                                <span>{translate('NumberofBedrooms')} : {props.payload.bedroom}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="houseBtns">
                        <a href="#" name={props.payload.id} onClick={props.goToPropertiesView}>{translate('Viewdetails')}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthenticatedComponent(HouseBookingServiceComponent);